// fn for YM ECOM
export function setYmDataLayer(item, event, list = false) {
    if(!item) return;

    const objLayer = {
        ecommerce: {
            currencyCode: "RUB",    
        }
    }

    switch (event) {
        case 'click':
            objLayer.ecommerce[event] = {};
            objLayer.ecommerce[event].products = [];
            setItemParams(objLayer.ecommerce[event].products)
            break;
    
        default:
            objLayer.ecommerce[event] = [];
            setItemParams(objLayer.ecommerce[event])
            break;
    }

    window.dataLayerYam.push(objLayer);

    function setItemParams(products) {
        if(item.length) {
            item.forEach((item, index) => {
                let el = item;
                let position = index + 1;
    
                if(
                    typeof item === 'object' &&
                    item.hasOwnProperty('position')
                ) {
                    el = item.el;
                    position = item.position;
                }
    
                products.push(createItemObj(el, position))
            })
        } else {
            products.push(createItemObj(item)) 
        }
    }

    function createItemObj(el, index = false) {
        const obj = {
            id: el.dataset.elementid,
        }
        const objElems = {
            name: el.querySelector('.product-title a'),
            price: el.querySelector('.product-price b'),
            brand: el.querySelector('[data-brand]'),
            category: el.querySelector('[data-section]'),
        }

        if(event === 'add') {
            obj.quantity = 1
        }

        if(el.dataset.parent) {
            obj.list = el.dataset.parent;
        } else if(list) {
            obj.list = list;
        } else {
            obj.list = `undefined title`;
        }

        for (const key in objElems) {
            if(!objElems[key]) continue;

            switch (key) {
                case 'name':
                    obj[key] = objElems[key].textContent.trim()
                    break;

                case 'price':
                    obj[key] = getPrice(objElems[key].textContent)
                    break;

                case 'brand':
                    if(!objElems[key].dataset.brand.length) {
                        break;
                    }

                    obj[key] = objElems[key].dataset.brand
                    break;

                case 'category':
                    if(!objElems[key].dataset.section.length) {
                        break;
                    }

                    obj[key] = objElems[key].dataset.section
                    break;
            }
        }

        if(index) {
            obj.position = index;
        } else {
            const position = el.getAttribute('aria-label');
    
            if(position) {
                obj.position = Number(position.replaceAll(' ', '').split('/')[0]);
            }
        }

        function getPrice(price) {
            if(!price || !price.length) return;
            price = price.replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1');
            return price;
        }

        return obj;
    }
}

export function setYmDataLayerPromo(arr, event) {
    if(!arr.length) return;

    const objLayer = {
        ecommerce: {}
    }

    objLayer.ecommerce[event] = {};
    objLayer.ecommerce[event].promotions = arr;
    window.dataLayerYam.push(objLayer);
}

export function cbYmDataLayerPromo(obj) {
    const { 
        swiper = false,
        swiperEvent = 'init',
        rtl = true,
        list = false
    } = obj;

    if(!swiper) return;

    const { slides, activeIndex, params } = swiper;
    const { slidesPerView } = params;
    const arr = [];

    switch (swiperEvent) {
        case 'activeIndexChange':
            let currentIndex;
            
            if(rtl) {
                currentIndex = slidesPerView + activeIndex - 1;
            } else {
                currentIndex = activeIndex;
            }

            setYmDataLayerPromoItem(currentIndex);
            break;
    
        default:
            let startIndex = activeIndex
            let qty = slidesPerView;

            if(slidesPerView === 'auto') {
                startIndex = 0;
                qty = slides.length
            }

            for (let index = startIndex; index < qty; index++) {
                setYmDataLayerPromoItem(index);
            }
            
            break;
    }

    function setYmDataLayerPromoItem(index) {

        if(typeof slides[index] !== 'undefined') {

            let creative;

            if(list) {
                creative = list
            } else if(slides[index].title && slides[index].title.length) {
                creative = slides[index].title
            } else {
                const lastCrumb = document.querySelector('.breadcrumbs-box li:last-child b');

                if(lastCrumb) {
                    creative = `Баннер раздела ${lastCrumb.textContent}`
                } else if(location.pathname.includes('/new')) {
                    creative = 'Баннер раздела Новинки';
                } else if(location.pathname.includes('/sale')) {
                    creative = 'Баннер раздела Рaспродажа';
                } else if(location.pathname.includes('/hit')) {
                    creative = 'Баннер раздела Хиты';
                } else {
                    creative = 'Баннер на главной';
                }
            }

            const obj = {
                id: slides[index].dataset.promoId,
                name: slides[index].dataset.promoName,
                position: index + 1,
                creative
            };

            arr.push(obj);

        }
    }

    setYmDataLayerPromo(arr, 'promoView');
};